jQuery( document ).ready(function() {
    
    // oop object for notifications area - use default settings but can provide json object for options (see init method)
	$(".notifications").makinoNotifications();

	// simple method for toggling sortable classes
	$(".sortable").click(function() {

		if ( !$(this).hasClass("sortable-asc") && !$(this).hasClass("sortable-desc") ) {
			$(this).addClass("sortable-asc");
		}
		$(this).toggleClass("sortable-asc").toggleClass("sortable-desc");
	
	});

});