var makinoNotifications = {

	'container': null
,	'wrapperSelector': ".wrap"
,	'expandedClass': ".expanded"
,	'elementSelector': ".notification"
,	'buttonSelector': ".btn-expand"
,	'iconSelector': "i"
,	'iconDown': "icon-angle-down"
,	'iconUp': "icon-angle-up"
,	'elementsToShow': 3

,	'init': function(container,options) {

		this.container = container;

	 	if ( options != undefined ) {

	 		if ( options.wrapperSelector != undefined ) {
	 			this.wrapperSelector = options.wrapperSelector;
	 		}
	 		if ( options.expandedClass != undefined ) {
	 			this.expandedClass = options.expandedClass;
	 		}
	 		if ( options.elementSelector != undefined ) {
	 			this.elementSelector = options.elementSelector;
	 		}
	 		if ( options.buttonSelector != undefined ) {
	 			this.buttonSelector = options.buttonSelector;
	 		}
	 		if ( options.iconSelector != undefined ) {
	 			this.iconSelector = options.iconSelector;
	 		}
	 		if ( options.iconDown != undefined ) {
	 			this.iconDown = options.iconDown;
	 		}
	 		if ( options.iconUp != undefined ) {
	 			this.iconUp = options.iconUp;
	 		}
	 		if ( options.toShow != undefined ) {
	 			this.elementsToShow = options.elementsToShow;
	 		}
	 	}

		this.adjustElementsToShow();
		this.expandToFit(this.elementsToShow);

		var jQueryObject = this;

		jQuery(this.container).find(this.buttonSelector).click(function(event){

			var wrapper = jQuery( jQueryObject.container.find(jQueryObject.wrapperSelector) );
			var icon = jQuery( jQuery(this).find(jQueryObject.iconSelector) );

			if ( !wrapper.hasClass(jQueryObject.expandedClass) ) {

				wrapper.addClass(jQueryObject.expandedClass);
				icon.removeClass(jQueryObject.iconDown).addClass(jQueryObject.iconUp);
				jQueryObject.expandToFit(false);
			}
			else {
				wrapper.removeClass(jQueryObject.expandedClass);
				icon.removeClass(jQueryObject.iconUp).addClass(jQueryObject.iconDown);
				jQueryObject.expandToFit(jQueryObject.elementsToShow);
			}

		});

		var resizeTimeOut;
		jQuery(window).resize(function() {

			clearTimeout(resizeTimeOut);
			var resizeTimeOut = setTimeout(function() {

				jQueryObject.adjustElementsToShow();
				jQueryObject.expandToFit(jQueryObject.elementsToShow);

			}, 500);

		});

	}
,	'expandToFit': function(elementsToShow) {

		var height = 0;
		var shownElements = 0;
		var container = this.container;

		jQuery(container).find(this.elementSelector).each(function() {

			if ( elementsToShow !== false && shownElements >= elementsToShow ) {
				return false;
			}

			height += jQuery(this).outerHeight();
			shownElements++;

		});

		jQuery(container).find(this.wrapperSelector).css("height", height);
		return;
	}

,	'adjustElementsToShow': function() {

		if ( $(window).width() < 768) {
			this.elementsToShow = 0;
		}
		else {
			this.elementsToShow = 3;
		}

	}

} 


jQuery.fn.makinoNotifications = function(options) {

 	var newObject = makinoNotifications;
 	newObject.init(this,options);
    return this;

};
